import { json } from '@remix-run/cloudflare';
import { Form, Link, useActionData, useLoaderData } from '@remix-run/react';
import { useCallback, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  Button,
  Input,
  Heading,
  Text,
  ErrorMessage,
  ButtonInner,
  InstagramLogo,
  TikTokLogo,
} from 'aslan';
import { readOrSetFlagsCookie, serializeFlagsCookie } from 'leap-auth';

import Verify from '~/components/FieldSets/OTPVerificationFields/OTPVerificationFields';
import MainLayout from '~/components/MainLayout/MainLayout';
import { fieldErrorMessage, baseErrorMessages } from '~/helpers/errors';
import {
  useLoadedTranslations,
  TranslationNamespace,
} from '~/helpers/getTranslations';
import {
  authProviderPath,
  forgotPasswordPath,
  registerPath,
} from '~/helpers/routes';

import type { LoaderFunctionArgs } from '@remix-run/cloudflare';
import type { LeapError } from 'leap-models';
import type { ChangeEvent } from 'react';

export { default as action } from './action.server';

export const loader = async (args: LoaderFunctionArgs) => {
  const [flags, newValue] = await readOrSetFlagsCookie(args, 'socialSignOn');

  return json(
    { socialSignOnEnabled: flags.socialSignOn },
    newValue
      ? {
          headers: {
            'Set-Cookie': await serializeFlagsCookie(args, flags),
          },
        }
      : undefined
  );
};

interface LoaderDataType {
  socialSignOnEnabled: boolean;
}

interface ActionDataType {
  errors?: LeapError[];
  otpSent?: boolean;
}

export default function Signin() {
  const data = useActionData<ActionDataType>();

  const { t } = useLoadedTranslations(TranslationNamespace.root);
  const { socialSignOnEnabled } = useLoaderData<LoaderDataType>();
  const [fields, setFields] = useState({
    email: '',
    password: '',
    handle: '',
  });

  const baseErrors = baseErrorMessages(data?.errors ?? []);

  const onInputChange = useCallback(
    (fieldName: string, value: string) => {
      setFields({
        ...fields,
        [fieldName]: value,
      });
    },
    [setFields, fields]
  );

  const resetFields = useCallback(() => {
    setFields({
      email: '',
      password: '',
      handle: '',
    });
  }, [setFields]);

  const updateEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onInputChange('email', e.target.value);
    },
    [onInputChange]
  );

  const updatePassword = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onInputChange('password', e.target.value);
    },
    [onInputChange]
  );

  const showVerifyFields =
    (data?.otpSent || data?.errors?.find((error) => error.field === 'code')) &&
    !!fields.email &&
    !!fields.password;

  const FORM_ID = 'signin-form';

  return (
    <MainLayout>
      <Form method="post" className="my-6 flex flex-col" id={FORM_ID}>
        {showVerifyFields && (
          <Verify onLinkClick={resetFields} formId={FORM_ID} />
        )}
        <fieldset
          className={twMerge(
            'flex flex-col space-y-5 my-6',
            showVerifyFields && 'hidden'
          )}
        >
          <Heading as="h1" size="3xl" className="font-serif">
            {t('signin-title')}
          </Heading>
          {baseErrors.length > 0 &&
            baseErrors.map((error) =>
              error.message ? (
                <ErrorMessage
                  key={error.message}
                  error={error.message}
                  className="mt-2"
                />
              ) : (
                ''
              )
            )}
          <div className="space-y-5">
            <Input
              label={t('email-field')}
              placeholder="jo@sabre.com"
              type="email"
              name="email"
              autoComplete="email"
              value={fields.email}
              onChange={updateEmail}
              error={fieldErrorMessage(data?.errors ?? [], 'email')?.message}
              required
            />
            <Input
              label={t('password-field')}
              placeholder="********"
              type="password"
              autoComplete="current-password"
              value={fields.password}
              onChange={updatePassword}
              name="password"
              required
            />
          </div>

          <Button
            className="mt-6"
            color="yellow"
            isFullWidth
            isRounded
            name="action"
            value="sendOTP"
          >
            {t('signin-cta')}
          </Button>
          {socialSignOnEnabled && (
            <>
              <Text as="span" size="sm" className="self-center">
                Or continue with
              </Text>
              <Link to={authProviderPath('instagram')} className="my-4">
                <ButtonInner
                  color="white"
                  isOutline
                  isFullWidth
                  isRounded
                  icon={<InstagramLogo className="w-10 h-10" />}
                >
                  Instagram
                </ButtonInner>
              </Link>
              <Link to={authProviderPath('tiktok')} className="my-4">
                <ButtonInner
                  color="white"
                  isOutline
                  isFullWidth
                  isRounded
                  icon={<TikTokLogo className="w-10 h-10" />}
                >
                  TikTok
                </ButtonInner>
              </Link>
            </>
          )}
          <Text as="span" size="sm" className="self-center">
            {t('go-to-sign-up')}{' '}
            <Link to={registerPath()} className="underline hover:no-underline">
              {t('sign-up-link')}
            </Link>{' '}
            {t('go-to-forgot-password')}{' '}
            <Link
              to={forgotPasswordPath()}
              className="underline hover:no-underline"
            >
              {t('forgot-password-link')}
            </Link>
          </Text>
        </fieldset>
      </Form>
    </MainLayout>
  );
}
